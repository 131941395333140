import { defineAsyncComponent } from 'vue';

import HawkLoader from '~/common/components/atoms/hawk-loader.vue';
import Fam from '~/forms-as-module/pages/fam.vue';
// sub-routes components
import FamOverview from '~/forms-as-module/pages/fam-overview.vue';

const FamCalendar = defineAsyncComponent({
  loader: () => import('~/forms-as-module/pages/fam-calendar.vue'),
  loadingComponent: HawkLoader,
});
const FamGallery = defineAsyncComponent({
  loader: () => import('~/forms-as-module/pages/fam-gallery/fam-gallery.vue'),
  loadingComponent: HawkLoader,
});
const FamKanban = defineAsyncComponent({
  loader: () => import('~/forms-as-module/pages/fam-kanban.vue'),
  loadingComponent: HawkLoader,
});
const FamList = defineAsyncComponent({
  loader: () => import('~/forms-as-module/pages/fam-list/fam-list.vue'),
  loadingComponent: HawkLoader,
});

const routes = [
  {
    path: '/:asset_id?/module/:template_uid/:template_name?',
    name: 'fam',
    component: Fam,
    meta: {

    },
    children: [
      {
        path: 'overview',
        name: 'fam-overview',
        component: FamOverview,
        meta: {

        },
      },
      {
        path: 'list',
        name: 'fam-list',
        component: FamList,
        meta: {

        },
      },
      {
        path: 'calendar',
        name: 'fam-calendar',
        component: FamCalendar,
        meta: {

        },
      },
      {
        path: 'kanban',
        name: 'fam-kanban',
        component: FamKanban,
        meta: {

        },
      },
      {
        path: 'gallery',
        name: 'fam-gallery',
        component: FamGallery,
        meta: {

        },
      },
    ],
  },
];
export default routes;
