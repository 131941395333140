<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import axios from 'axios';

import parser from 'ua-parser-js';
import { useRoute } from 'vue-router';
import AuditLogActivity from '~/acct-settings/components/acct-settings-audit-logs/audit-log-activity.vue';

import CollapsibleDescription from '~/acct-settings/components/acct-settings-audit-logs/collapsible-description.vue';
import { useAuthStore } from '~/auth/stores/auth.store';

import HawkDateRangeFilter from '~/common/components/organisms/hawk-filters/hawk-date-range-filter.vue';
import HawkTable from '~/common/components/organisms/hawk-table/hawk-table.vue';
import TableWrapperVue from '~/common/components/organisms/hawk-table/table.wrapper.vue';

import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import { useCommonStore } from '~/common/stores/common.store';
import { useResourceHelper } from '~/common/utils/common.utils.js';

import { $date } from '~/common/utils/date.util';
import { useFamConstants } from '~/forms-as-module/composables/fam-constants.composable.js';

const $services = inject('$services');
const route = useRoute();
const common_store = useCommonStore();
const { show_audit_logs } = useAuthStore();
const { $t } = useCommonImports();

const { ACCOUNT_SETTING_DATE_FILTERS } = useFamConstants();
const { getResourceName, getResourceType } = useResourceHelper();

/* ---------- CONSTANT DATA ---------- */
const MODULE_TO_RESOURCE_MAP = {
  FORMS: ['FORM_TEMPLATE', 'FORM'],
  CORE: ['USER', 'TEAM', 'ROLE', 'ASSET', 'ORGANIZATION', 'CATEGORY', 'TAG', 'CONTAINER', 'GROUP', 'PROJECT', 'REPORT'],
  PROJECT_MANAGEMENT: ['SCHEDULE', 'ACTIVITY'],
  DMS: ['FILE', 'FOLDER', 'TRANSMITTAL'],
  INVENTORY: ['ITEM', 'STOCK', 'ADJUSTMENT', 'WAREHOUSE', 'VENDOR', 'CUSTOM_FIELD'],
  PLANS: ['DRAWING', 'SHEET'],
  SYSTEM_MODEL: ['TEMPLATE', 'CATEGORY', 'COMPONENT', 'CUSTOM_FIELD'],
  TERRA: ['FEATURE', 'FEATURE_TYPE', 'FEATURE_TYPE_GROUP', 'WORKFLOW'],
  TASKS: ['TICKET'],
  DASHBOARDS_REPORTS: ['DASHBOARD'],
  THERM: ['DEFECT'],
};

const RESOURCES = Object.keys(MODULE_TO_RESOURCE_MAP).reduce((acc, module) => {
  const module_resources = MODULE_TO_RESOURCE_MAP[module].map(resource_key => ({
    name: `${module}.${resource_key}`,
    uid: resource_key,
  }));
  acc = [...acc, ...module_resources];
  return acc;
}, []);

const COLUMNS = [
  {
    accessorKey: 'created_at',
    header: 'Time stamp',
    id: 'created_at',
    size: '260',
    enableSorting: false,
  },
  {
    accessorFn: row => getResourceType(row),
    header: 'Type',
    id: 'resource_name',
    enableSorting: false,
    render_as: {
      field_type: 'text',
      options: {
        truncate_config: {
          type: 'adaptive',
        },
      },
    },
  },
  {
    accessorFn: row => getResourceName(row),
    header: 'Resource',
    id: 'resource',
    size: '175',
    enableSorting: false,
    render_as: {
      field_type: 'text',
      options: {
        truncate_config: {
          type: 'adaptive',
        },
      },
    },
  },
  {
    accessorKey: 'asset',
    header: 'Asset',
    id: 'asset',
    size: '175',
    enableSorting: false,
    render_as: {
      field_type: 'asset',
      options: {
        truncate_config: {
          type: 'adaptive',
        },
      },
    },
  },
  {
    accessorKey: 'meta',
    header: 'Event',
    id: 'meta',
    size: '400',
    enableSorting: false,
  },
];

/* ---------- REACTIVE DATA ---------- */
const filters = ref(null);

const table_instance = ref(null);

const state = reactive({
  is_loading: false,
  is_loading_on_scroll: false,
  page_number: 1,
  page_size: 100,
  total_logs: 0,
  audit_logs: [],
  date_filter: { value: 'past_1_day' },
});

const display_filters = computed(() => [
  {
    name: $t('Resource'),
    is_static: true,
    data_type: 'single_select',
    operators: ['isAnyOf'],
    options: RESOURCES,
    uid: 'resource_name',
  },
  {
    name: $t('Actor'),
    is_static: true,
    data_type: 'single_select',
    operators: ['isAnyOf'],
    option_type: 'member',
    options: [
      { name: 'Sensehawk Bot', uid: 'sensehawk' },
      ...common_store.scope_users(route.params?.asset_id || null),
    ],
    type: 'member',
    uid: 'actor',
  },
  {
    name: $t('Asset'),
    is_static: true,
    data_type: 'single_select',
    operators: ['isAnyOf'],
    option_type: 'assets',
    options: [],
    uid: 'activity_asset',
  },
]);

/* ---------- FUNCTIONS ---------- */
async function getData({ pagination_state }) {
  if (pagination_state?.pageIndex) {
    state.is_loading_on_scroll = true;
  }
  else {
    table_instance?.value?.resetPagination(0);
    state.is_loading = true;
  }

  try {
    const query = {
      sort: '-createdAt',
      page_number: 1,
      page_size: state.page_size,
      created_at_start: state.date_filter.active_range[0].toISOString(),
      created_at_end: state.date_filter.active_range[1].toISOString(),
      ...getFilterQuery(),
    };

    if (pagination_state?.pageIndex) {
      query.page_number = pagination_state.pageIndex + 1;
      state.page_number = query.page_number;
    }

    const { data, headers } = await $services.common.get_audit_logs({ query });
    if (pagination_state) {
      state.audit_logs = [...state.audit_logs, ...data];
    }
    else {
      state.audit_logs = data;
      state.total_logs = Number(headers['x-total-count']);
    }
  }
  catch (e) {
    logger.log({ e });
  }

  if (pagination_state?.pageIndex)
    state.is_loading_on_scroll = false;
  else state.is_loading = false;
}

async function applyDateFilter(e) {
  state.date_filter = e;
  await getData({});
}

function getFilterQuery() {
  if (!filters.value?.filters?.length)
    return {};

  const applied_filters = filters.value.filters;
  return applied_filters.reduce((acc, filter) => {
    acc[filter.field] = filter.value;
    return acc;
  }, {});
}

async function toggleExpanded(log_uid) {
  const log_index = state.audit_logs.findIndex(log => log.uid === log_uid);
  state.audit_logs[log_index].is_expanded = !state.audit_logs[log_index].is_expanded;

  if (state.audit_logs[log_index].is_expanded) {
    const event = state.audit_logs[log_index];
    if (event.ip_address && !event.Geography) {
      const { data } = await axios.get(`https://ipapi.co/${event.ip_address}/json/`);
      if (data) {
        state.audit_logs[log_index].Geography = {
          City: data?.city || 'NA',
          Country: data?.country || 'NA',
          State: data?.region || 'NA',
        };
      }
    }
  }
}

function getUser(actor) {
  return actor.split(':')[1];
}

/* ---------- DESCRIPTION DATA FUNCTIONS ---------- */
function getActor(user_string) {
  const getUserFullName = user => user.first_name ? `${user.first_name} ${user.last_name || ''}` : user.username;

  const user_id = getUser(user_string);
  const Actor = {
    key: $t('Actor'),
    value: 'NA',
    child: {
      Type: 'NA',
    },
  };

  if (user_id === 'sensehawk') {
    Actor.value = 'Sensehawk (Automatic)';
    Actor.child.Type = 'Automatic';
  }
  else if (common_store.get_user(user_id) || common_store.get_org_or_internal_user(user_id)) {
    const user = common_store.get_user(user_id) || common_store.get_org_or_internal_user(user_id);
    Actor.value = `${getUserFullName(user)} (id: ${user_id})`;
    Actor.child = {
      Id: user_id,
      Type: `${user.user_type.charAt(0).toUpperCase()}${user.user_type.slice(1)}`,
      Name: getUserFullName(user),
      Email: user.email,
    };
  }

  return Actor;
}

function getDevice(event) {
  const Ip = event.ip_address || 'NA';

  const Geography = event.Geography
    ? {
        key: $t('Geography'),
        value: `${event.Geography.City}, ${event.Geography.Country}`,
        child: event.Geography,
      }
    : 'NA';

  const Device = {
    key: $t('Device'),
    value: 'NA',
    child: {
      'Type': 'NA',
      'IP': Ip,
      'User agent': 'NA',
      'Geography': Geography,
    },
  };

  if (event.user_agent) {
    const device_details = parser(event.user_agent);
    const browser_name = `${device_details?.browser.name || 'NA'} ${device_details?.browser?.version || ''}`;
    const os_name = `${device_details?.os?.name || 'NA'} ${device_details?.os.version || ''}`;

    Device.value = `${browser_name} on ${os_name}, IP: ${Ip}`;
    Device.child.Type = os_name;
    Device.child['User agent'] = {
      key: 'User agent',
      value: `${browser_name} on ${os_name}`,
      child: {
        Browser: browser_name,
        OS: os_name,
        Raw: event.user_agent,
      },
    };
  }
  return Device;
}

function getEvent(activity) {
  return {
    key: $t('Event'),
    value: {
      component: AuditLogActivity,
      props: { activity },
    },
    child: {
      'Id': activity.uid,
      'Type': `${activity.module}.${activity.resource_name}.${activity.verb}`,
      'Message': {
        component: AuditLogActivity,
        props: { activity },
      },
      'Resource type': getResourceType(activity),
      'Resource name': getResourceName(activity, 'NA'),
    },
  };
}
</script>

<template>
  <div>
    <HawkPageHeader class="!px-0">
      <template #title>
        <div>
          <div>{{ $t('Audit logs') }}</div>
          <p class="text-sm text-gray-600 font-normal">
            {{ $t('Track and review all system, user activities with detailed logs for improved compliance') }}
          </p>
        </div>
      </template>
    </HawkPageHeader>
    <div v-if="show_audit_logs">
      <div class="flex justify-between">
        <HawkDisplayFilters
          ref="filters"
          :display_filters="display_filters"
          @apply="getData({})"
        />
        <HawkDateRangeFilter
          :active-range="state.date_filter"
          :date-filters="ACCOUNT_SETTING_DATE_FILTERS"
          @filter-applied="applyDateFilter"
        />
      </div>
      <TableWrapperVue v-if="state.is_loading || state.audit_logs.length" container_class="my-4 border audit-log-table">
        <HawkTable
          :pagination_config="{ totalRows: state.total_logs, pageSize: state.page_size }"
          :data="state.audit_logs"
          :is_loading="state.is_loading"
          :is_loading_on_scroll="state.is_loading_on_scroll"
          :columns="COLUMNS"
          :manual_sorting="true"
          :enable_infinite_scroll="true"
          is_gapless
          @load-more="getData"
          @table-instance="table_instance = $event"
        >
          <template v-for="row in state.audit_logs.filter(log => log.is_expanded)" :key="row.uid" #[`row_info_${row.uid}`]>
            <div class="w-[90vw]">
              <CollapsibleDescription :data="getActor(row.actor)" />
              <CollapsibleDescription v-if="getUser(row.actor) !== 'sensehawk'" :data="getDevice(row)" />
              <CollapsibleDescription :data="getEvent(row)" />
            </div>
          </template>
          <template #created_at="created_at">
            <hawk-button
              icon
              type="plain"
              class="ml-[-15px]"
              @click="toggleExpanded(created_at.data.row.original.uid)"
            >
              <IconHawkChevronRight
                class="text-white transition-transform"
                :class="{ 'rotate-90 !visible': created_at.data.row.original.is_expanded }"
              />
            </hawk-button>
            <p class="text-gray-500 whitespace-nowrap">
              {{ $date(created_at.data.getValue(), 'DD MMM YYYY hh:mm A') }}
            </p>
          </template>
          <template #meta="meta">
            <AuditLogActivity :activity="meta.data.row.original" />
          </template>
        </HawkTable>
      </TableWrapperVue>
      <HawkIllustrations v-else type="no-results" />
    </div>
    <HawkIllustrations v-else type="no-permission" />
  </div>
</template>

<style lang="scss">
.audit-log-table tr .table-cell {
  padding-left: 40px!important;
}
</style>
